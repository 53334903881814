$menuHeight: 65px+10px;

@mixin transition {
  transition-property: background-color opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

#mazeContainer {
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
  top: $menuHeight;
  opacity: 0;
  display: inline-block;
  margin: auto;

  #mazeCanvas {
    margin: 0;
    display: block;
    border: solid 1px black;
  }
}

#menu {
  margin-bottom: 1rem;
}

.game__main {
  max-width: 600px;
}

input,
select {
  @include transition;
  cursor: pointer;
  height: 40px;
  width: 90px;
  background: #cccc;
  margin-right: 1rem;
  border: none;
  border-radius: 5px;
  display: inline-block;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  appearance: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.70);
  }

  &:active {
    background-color: black;
  }

  &:focus {
    outline: none;
  }
}


.custom-select {
  display: inline-block;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 125px center;
  }
}

#Message-Container {
  visibility: hidden;
  color: white;
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.30);
  z-index: 1;

  #message {
    width: 300px;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -150px;
  }
}

#page {
  height: auto;
  width: auto;
  margin: auto;
  text-align: center;

  #menu {
    margin: auto;
    padding: 10px;
    height: 65px;
    box-sizing: border-box;

    h1 {
      margin: 0;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 3.2rem;
    }
  }

  #view {
    width: 100%;
    height: auto;

  }
}

.border {
  border: 1px black solid;
  border-radius: 5px;
}


#gradient {
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) {
  input, select {
    width: 120px;
  }
}